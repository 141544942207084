
.swal2-container.swal2-center {
    z-index: 2147483647
}

.right{
    justify-content: flex-end;
    text-align: right;
}

.field-grid-header.ag-right-aligned-header{
    justify-content: flex-end;
}


.compacto .MuiInputBase-input.MuiOutlinedInput-input{
    font-size: 10px;
}