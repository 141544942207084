.ag-cell{
    display: flex;
    align-items: center;
    padding-bottom: 4px;

}

.ag-header-cell-text{
    white-space:normal;
    color: black;
    font-size: 1.2em;
    text-align: center;
}

.ag-theme-material {
    /*--ag-background-color: #ddd;*/
}

.ag-cell-wrapper{
    width: 100%;
}

/*.ag-cell-value{*/
/*    display: flex;*/
/*}*/

/*.right .ag-cell-value{*/
/*    justify-content: right;*/
/*}*/

.center .ag-cell-value,.center .ag-header-cell-label{
    justify-content: center;
}

/*.icon-container.ag-cell{*/
/*    padding-left: 0;*/
/*    padding-right: 0;*/
/*}*/